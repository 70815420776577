require('../vendor/slabtext-lib');


$(document).ready(function () {
  if(breakpoints.isDesktop()) {
    $('[data-trigger="slabText"] p').slabText();
  }
  else {
    $('.header__presentation-title p').css('font-size','43px').css('line-height', '1.02');

  }
});
